import { useContext, useEffect, useState } from "react";
import { EditableContext } from "../common/EditableContextProvider";
import { Table, Empty, Modal } from "antd";
import { sprintf } from "sprintf-js";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import FormError from "../common/FormError";
import JoditEditor from "jodit-react";

const { Column } = Table;

const PaginasComponent = () => {
    const { editItem, paginas } = useContext(EditableContext);
    const [pagItems, setPagItems] = useState(editItem.paginas);
    const [editCat, setEditCat] = useState({});
    const [showModalCat, setShowModalCat] = useState(false);
    const [loading, setLoading] = useState(false);

    const [contenido, setContenido] = useState(editCat.contenido);

    useEffect(() => {
        setContenido(editCat.contenido);
    }, [editCat]);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    useEffect(() => {
        setPagItems(editItem.paginas);
    }, [editItem.paginas]);

    useEffect(() => {
        paginas.updateEditItemPaginas(pagItems);
    }, [pagItems]);

    const handle_new = () => {
        setEditCat({
            id: 0,
            mercado_id: editItem.id,
            titulo: "",
            contenido: ""
        });
        setShowModalCat(true);
    }

    const handle_edit = (item) => {
        setEditCat(item);
        setShowModalCat(true);
    }

    const handle_submit = async (values) => {
        values.contenido = contenido;

        setLoading(true);
        try {
            let action = (values.id) ? paginas.updateItemPagina(values) : paginas.addItemPagina(values);
            let it = await action;

            if (values.id) {
                setPagItems(prev => prev.map(cat => {
                    if (cat.id !== values.id) { return cat; }
                    else { return it; }
                }));
            } else { setPagItems(prev => ([...prev, it])); }

            setShowModalCat(false);
        } catch (error) { console.log(error.message); }
        finally { setLoading(false); }
    }

    const handle_remove = (id) => {
        Modal.confirm({
            title: "Confirmar",
            content: "¿Eliminar item?",
            onOk: async () => {
                try {
                    let action = paginas.removeItemCategoria(id);
                    await action;
                    setPagItems(prev => prev.filter(it => it.id !== id));
                    setShowModalCat(false);
                } catch (error) { console.log(error.message); }
            }
        });
    }

    const catValidationSchema = Yup.object({
        titulo: Yup.string()
            .min(3, 'Texto muy corto')
            .max(200, 'Texto muy largo')
            .required('El campo es obligatorio'),
    });

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button className="btn btn-sm btn-action btn-light border py-0" title="Editar"
                    onClick={() => handle_edit(item)} >
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                    onClick={() => handle_remove(item.id)} >
                    <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    return (
        <>
            <div className="mb-2">
                <button className="btn btn-sm btn-link"
                    onClick={() => handle_new()}
                >
                    [+] Agregar
                </button>
            </div>
            <Table
                size="small"
                dataSource={pagItems}
                rowKey="id"
            >
                <Column key="a1" title="·" dataIndex="id" width={60}
                    render={(val, row) => (<ActionButtons item={row} />)}
                />
                <Column key="id" title="ID" dataIndex="id" width={60}
                    render={(value) => (<>{sprintf("%02d", value)}</>)}
                />
                <Column key="titulo" title="Título" dataIndex="titulo" width={200} />
                <Column key="slug" title="Slug" dataIndex="slug" width={200} />
            </Table>

            <Modal
                open={showModalCat}
                onCancel={() => setShowModalCat(false)}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                <Formik
                    initialValues={editCat}
                    onSubmit={handle_submit}
                    validationSchema={catValidationSchema}
                >
                    {
                        (formik) => (
                            <Form>
                                <fieldset disabled={loading}>
                                    <div className="mb-2">
                                        <label>Título</label>
                                        <input type="text" className="form-control form-control-sm"
                                            value={formik.values.titulo}
                                            onChange={(e) => formik.setFieldValue('titulo', e.target.value)}
                                        />
                                        <ErrorMessage name="nombre" component={FormError} />
                                    </div>
                                    <div className="mb-2">
                                        <label>Contenido</label>
                                        
                                        <JoditEditor
                                            value={contenido}
                                            onChange={(content) => setContenido(content)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <button className="btn btn-sm btn-dark">
                                            Guardar
                                        </button>
                                    </div>
                                </fieldset>
                            </Form>
                        )
                    }
                </Formik>
            </Modal>
        </>
    );
}

export default PaginasComponent;