import { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { EditableContext } from "../common/EditableContextProvider";
import { Modal } from "antd";

const LogoComponent = () => {
    const { editItem, uploadImage, deleteImage } = useContext(EditableContext);
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { confirm } = Modal;

    const onDrop = useCallback(acceptedFile => {
        setError(false);
        setFile(acceptedFile);
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "image/jpeg": ['.jpg', '.jpeg'],
            "image/png": ['.png'],
            "image/gif": ['.gif'],
            "image/webp": ['.webp'],
            "image/bmp": ['.bmp']
        }
    });

    const handle_submit = async (e) => {
        e.preventDefault();
        if(!file) { return; }

        setLoading(true);
        await uploadImage(file);
        setFile("");
        setLoading(false);
    }

    const reset = () => {
        setFile("");
    }

    const handle_delete = () => {
        confirm({
            title: "Confirmar",
            content: "¿Eliminar imagen?",
            onOk: async () => {
                await deleteImage();
            }
        });
    }

    return (
        <div className="row">
            <div className="col-md-6 pb-3">
                {
                    (editItem.image) ? (
                        <div key={editItem.image} className="position-relative">
                            <img src={`/storage/${editItem.image}`} className="w-100 rounded-3 border"
                                alt="" style={{ maxHeight: '500px', objectFit: 'contain' }} />
                            <button className="btn btn-sm bg-white position-absolute py-0 px-0 border-danger me-1 mb-1" title="Eliminar"
                                onClick={() => { handle_delete() }}
                                style={{ right: '5px', bottom: '5px' }}
                            >
                                <i className="fa-solid fa-trash p-1 text-danger"></i>
                            </button>
                        </div>
                    ) : (
                        <div className="text-dark w-100 text-center py-4">Sin imagen.</div>
                    )
                }

            </div>
            <div className="col-md">
                <form onSubmit={(e) => handle_submit(e)}>
                    <p className="h6">Subir imagen</p>
                    <div className='dropzone text-center mb-2' {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <div className='w-100'>Suelta la imagen aquí...</div> :
                                <div className='w-100'>Arrastra alguna imagen aquí, o haz click para seleccionar un archivo.</div>
                        }
                    </div>
                    <div className="mb-2">
                        {
                            error ? <FormError>No hay imagen</FormError> : ''
                        }
                    </div>
                    <fieldset disabled={loading}>
                        <div className='mb-2 d-flex justify-content-between'>
                            <button type='button' className="btn btn-sm btn-light border border-secondary"
                                onClick={() => reset()}
                            >
                                <i className="fa-solid fa-xmark me-2"></i>
                                Cancelar
                            </button>
                            <button className="btn btn-sm btn-light border border-secondary" >
                                <i className="fa-solid fa-upload me-2"></i>
                                {loading ? "Subiendo..." : "Subir"}
                            </button>
                        </div>
                    </fieldset>
                </form>
                <div className='p-2 d-flex flex-wrap'>
                    {
                        (file) ? (
                            <div className='rounded border border-dark overflow-hidden w-25 mt-1 me-1'>
                                <img src={URL.createObjectURL(file[0])} style={{ width: '100%' }} alt="" />
                            </div>
                        ) : (
                            <div>Vacio.</div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default LogoComponent;