import { useReducer, useState } from "react";
import { 
    getItems, createItem, updateItem, defaultItem, 
    deleteItem, reducer, 
    defaultCategoria, defaultSubcategoria,

    createCategoria, updateCategoria, deleteCategoria,
    createSubcategoria, updateSubcategoria, deleteSubcategoria,

    loadImage, delImage,

    createPagina, updatePagina, deletePagina
} from "./functions";

const useProductos = () => {
    const [items, dispatch] = useReducer(reducer);
    const [editItem, setEditItem] = useState(defaultItem);
    const [loading, setLoading] = useState(true);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 10, "total": 0 },
        filters: {},
        sorter: {},
    });

    const fillItems = async (params = tableParams) => {
        try {
            setLoading(true);
            let { data } = await getItems(params);
            let { rows, total, current } = data;

            dispatch({ type: "FILL", payload: rows });
            setTableParams(prev => {
                return {
                    ...prev,
                    ...params,
                    pagination: {
                        ...prev.pagination,
                        ...params.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { console.log(error.message); }
        finally { setLoading(false); }
    }
    const newItem = () => { setEditItem(defaultItem); }

    const saveItem = async (item) => {
        try {
            let item_action = (item.id === 0) ? createItem(item) : updateItem(item);
            let res = await item_action;

            if (item.id === 0) { 
                item.id = res.data.id;
                /* dispatch({ type: "ADD", payload: item }); */
                fillItems();
            } else {
                dispatch({ type: "UPDATE", payload: item });
            }
            setEditItem(item);
            
        } catch (error) {
            if(error.response) {
                let { status, data } = error.response;
                if(status === 422) {
                    let { errors } = data;
                    throw { code: 'REQ_FAIL', message: 'Error en la validación.', errors: errors };
                } else {
                    throw { code: 'SERVER_ERROR', message: 'Error interno.' };
                }
            }
        } finally { setLoading(false); }
    }

    const selectItem = (item) => {
        setEditItem({...item});
    }

    const destroyItem = async (id) => {
        try {
            await deleteItem(id);
            fillItems();
        } catch (error) { console.log(error); }
    }

    /* imagen */

    const uploadImage = (file) => {
        return new Promise(async (resolve, reject) => {
            try {
                let formData = new FormData();
                formData.append('image', file[0]);
                let res = await loadImage(editItem.id, formData);
                setEditItem(prev => { return { ...prev, image: res.data.image } });
                resolve();
            } catch (e) {
                reject(e);
            }
        });

    }

    const deleteImage = () => {
        return new Promise(async (resolve, reject) => {
            await delImage(editItem.id).catch(e => reject(e));
            setEditItem(prev => { return {...prev, image: ""}; })
            resolve();
        });
    }

    /* fin imagen */

    /* categorías */ 
    const addItemCategoria = async (item) => {
        try {
            let res = await createCategoria(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const updateItemCategoria = async (item) => {
        try {
            let res = await updateCategoria(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const removeItemCategoria = async (id) => {
        try {
            let res = await deleteCategoria(id);
        } catch (error) { console.log(error.message); }
    }
    /* fin categorías */
    
    /* subcategorias */
    const addItemSubcategoria = async (item) => {
        try {
            let res = await createSubcategoria(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const updateItemSubcategoria = async (item) => {
        try {
            let res = await updateSubcategoria(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const removeItemSubcategoria = async (id) => {
        try {
            let res = await deleteSubcategoria(id);
        } catch (error) { console.log(error.message); }
    }

    const updateEditItemCategorias = (categorias) => {
        let n_it = {...editItem, categorias: categorias};
        setEditItem(n_it);
        dispatch({type: "UPDATE", payload: n_it });
    }
    /* fin subcategorias */

    /* paginas */
    const addItemPagina = async (item) => {
        try {
            let res = await createPagina(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const updateItemPagina = async (item) => {
        try {
            let res = await updatePagina(item);
            return res.data;
        } catch (error) { console.log(error.message); }
    }

    const removeItemPagina = async (id) => {
        try {
            let res = await deletePagina(id);
        } catch (error) { console.log(error.message); }
    }

    const updateEditItemPaginas = (paginas) => {
        let n_it = {...editItem, paginas: paginas};
        setEditItem(n_it);
        dispatch({type: "UPDATE", payload: n_it });
    }
    /* fin paginas */

    return {
        editItem,
        fillItems,
        items,
        selectItem,
        newItem,
        saveItem,
        destroyItem,
        loading,
        tableParams,

        uploadImage,
        deleteImage,

        categorias: { 
            defaultCategoria, addItemCategoria, updateItemCategoria, removeItemCategoria,
            updateEditItemCategorias
        },
        subcategorias: { 
            defaultSubcategoria,addItemSubcategoria, updateItemSubcategoria, removeItemSubcategoria 
        },
        paginas: {
            addItemPagina, updateItemPagina, removeItemPagina, updateEditItemPaginas
        }
    };

}

export default useProductos;