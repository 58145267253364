import { Table } from "antd";

const TableComponent = ({
    columns, items, isLoading, tableParams, onTableChange }) => {

    return (
        <div>
            <Table
                caption="Tabla de Usuarios"
                columns={columns}
                size="small"
                dataSource={items}
                rowKey="id"
                loading={isLoading}
                onChange={onTableChange}
                pagination={{ ...tableParams.pagination, position: ['bottomCenter'], showSizeChanger: true }}
                bordered
                scroll={{ y: 400, }}
            />
        </div>
    );
}

export default TableComponent;