import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState, useContext } from "react";
import * as Yup from "yup";
import { Select, Spin, Tabs } from 'antd';
import FormError from "../common/FormError";
import { EditableContext } from "../common/EditableContextProvider";
import CategoriasComponent from "./CategoriasComponent";
import SubcategoriasComponent from "./SubcategoriasComponent";
import LogoComponent from "./LogoComponent";
import PaginasComponent from "./PaginasComponent";

const FormComponent = () => {
    const { editItem, onFormAction } = useContext(EditableContext);
    const [initialValues, setInitialvalues] = useState(editItem);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInitialvalues(editItem);
    }, [editItem]);

    const handleSubmit = async (values, formik) => {
        try {
            setLoading(true);
            await onFormAction(values);
            setInitialvalues(values);
        } catch (e) {
            if (e.code === "REQ_FAIL") {
                if (e.errors.nombre) { formik.setFieldError("nombre", e.errors.nombre[0]); }
            }
        }
        finally { setLoading(false); }
    }

    const validateSchema = Yup.object({
        nombre: Yup.string()
            .min(3, 'El nombre debe tener al menos 3 letras.')
            .max(191, 'El nombre es demasiado largo')
            .required('El campo nombre es obligario.'),
        descripcion: Yup.string()
            .max(250, 'El texto es demasiado largo')
            .nullable(),
    });

    return (
        <div>
            <Tabs
                items={[
                    {
                        key: "formulario",
                        label: "Formulario",
                        children: (<div className="">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validateSchema}
                                enableReinitialize={true}
                            >
                                {
                                    (formik) => (
                                        <Form disabled={loading} encType="multipart/form-data" >
                                            <div className="row mb-2">
                                                <div className="col-md">
                                                    <div className="py-1">
                                                        <span>ID: </span>
                                                        <span className="fw-bold ms-1">{sprintf('%04d', editItem.id)}</span>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="nombre" className="form-label">
                                                            Nombre
                                                            <span className="text-danger">(*)</span>
                                                        </label>
                                                        <input id="nombre" name="nombre" type="text" className="form-control form-control-sm" 
                                                            value={formik.values.nombre??""}
                                                            onChange={(e) => formik.setFieldValue('nombre', e.target.value)}
                                                        />
                                                        <ErrorMessage name="nombre" component={FormError} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label htmlFor="descripcion" className="form-label">
                                                            Descripción
                                                        </label>
                                                        <input id="descripcion" name="descripcion" type="descripcion" className="form-control form-control-sm" 
                                                            value={formik.values.descripcion??""}
                                                            onChange={(e) => formik.setFieldValue('descripcion', e.target.value)}
                                                        />
                                                        <ErrorMessage name="descripcion" component={FormError} />
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    {/* Aqui van otros campos */}
                                                </div>
                                            </div>

                                            <div className="mb-2 d-flex justify-content-between">
                                                <div>
                                                    <span className="text-danger">(*) </span>
                                                    <u>Campo obligatorio.</u>
                                                </div>
                                                <button type="submit" className="btn btn-sm btn-light border border-secondary px-4">
                                                    {
                                                        (loading) ? (
                                                            <Spin size="small" />
                                                        ) : "Guardar"
                                                    }
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </div>)
                    },
                    {
                        key: "logo",
                        label: "Logo",
                        children: ((editItem.id) ? (<LogoComponent />) :
                            (<div className="">Primero guarda el documento.</div>))
                    },
                    {
                        key: "categorias",
                        label: "Categorías",
                        children: ((editItem.id) ? (<CategoriasComponent />) :
                            (<div className="">Primero guarda el documento.</div>))
                    },
                    {
                        key: "subcategorias",
                        label: "SubCategorías",
                        children: ((editItem.id) ? (<SubcategoriasComponent />) :
                            (<div className="">Primero guarda el documento.</div>))
                    },
                    {
                        key: "paginas",
                        label: "Páginas",
                        children: ((editItem.id) ? (<PaginasComponent />) :
                            (<div className="">Primero guarda el documento.</div>))
                    },
                ]}
            ></Tabs>
        </div>
    );
}

export default FormComponent;