import { useContext, useEffect, useState } from "react";
import { EditableContext } from "../common/EditableContextProvider";
import { Table, Empty, Modal, Col } from "antd";
import { sprintf } from "sprintf-js";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import FormError from "../common/FormError";

const { Column } = Table;

const CategoriasComponent = () => {
    const {editItem, categorias} = useContext(EditableContext);
    const [catItems, setCatItems] = useState(editItem.categorias);
    const [editCat, setEditCat] = useState({});
    const [showModalCat, setShowModalCat] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCatItems(editItem.categorias);
    }, [editItem.categorias]);

    useEffect(() => {
        categorias.updateEditItemCategorias(catItems);
    }, [catItems]);

    const handle_new = () => {
        setEditCat({
            id: 0,
            mercado_id: editItem.id,
            nombre: ""
        });
        setShowModalCat(true);
    }

    const handle_edit = (item) => {
        setEditCat(item);
        setShowModalCat(true);
    }

    const handle_submit = async (values) => {
        setLoading(true);
        try {
            let action = (values.id)? categorias.updateItemCategoria(values) : categorias.addItemCategoria(values);  
            let it= await action;

            if(values.id) {
                setCatItems(prev => prev.map(cat => {
                    if(cat.id !== values.id) { return cat; }
                    else { return it; }
                }));
            } else { setCatItems(prev => ([...prev, it])); }

            setShowModalCat(false);
        } catch (error) { console.log(error.message); }
        finally { setLoading(false); }
    }

    const handle_remove = (id) => {
        Modal.confirm({
            title: "Confirmar",
            content: "¿Eliminar item?",
            onOk: async () => {
                try {
                    let action = categorias.removeItemCategoria(id);
                    await action;
                    setCatItems(prev => prev.filter(it => it.id!== id));
                    setShowModalCat(false);
                } catch (error) { console.log(error.message); }
            }
        });
    }

    const catValidationSchema = Yup.object({
        nombre: Yup.string()
            .min(3, 'Texto muy corto')
            .max(200, 'Texto muy largo')
            .required('El campo es obligatorio')
    });

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button className="btn btn-sm btn-action btn-light border py-0" title="Editar"
                    onClick={() => handle_edit(item)} >
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                    onClick={() => handle_remove(item.id)} >
                    <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    return (
        <>
            <div className="mb-2">
                <button className="btn btn-sm btn-link"
                    onClick={() => handle_new()}
                >
                    [+] Agregar
                </button>
            </div>
            <Table
                size="small"
                dataSource={catItems}
                rowKey="id"
                /* expandable={{ 
                    expandedRowRender: (row) => (<>
                        hello <br /> 
                        hello <br /> 
                        hello <br /> 
                        hello <br /> 
                    </>)
                 }} */
            >
                <Column key="a1" title="·" dataIndex="id" width={60} 
                    render={(val,row) => (<ActionButtons item={row} />)}
                />
                <Column key="id" title="ID" dataIndex="id" width={60} 
                    render={(value) => (<>{sprintf("%02d", value) }</>)}
                />
                <Column key="nombre" title="Nombre" dataIndex="nombre" width={200} />
            </Table>

            <Modal
                open={showModalCat}
                onCancel={() => setShowModalCat(false)}
                footer={null}
                destroyOnClose={true}
            >
                <Formik
                    initialValues={editCat}
                    onSubmit={handle_submit}
                    validationSchema={catValidationSchema}
                >
                        {
                            (formik) => (
                                <Form>
                                    <fieldset disabled={loading}>
                                        <div className="mb-2">
                                            <label>Nombre</label>
                                            <input type="text" className="form-control form-control-sm" 
                                                value={formik.values.nombre}
                                                onChange={(e) => formik.setFieldValue('nombre', e.target.value)}
                                            />
                                            <ErrorMessage name="nombre" component={FormError} />
                                        </div>
                                        <div className="mb-2">
                                            <button className="btn btn-sm btn-dark">
                                                Guardar
                                            </button>
                                        </div>
                                    </fieldset>
                                </Form>
                            )
                        }
                </Formik>
            </Modal>
        </>
    );
}

export default CategoriasComponent;