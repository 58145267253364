require('./bootstrap');
require('./components/usuarios/Main');

import Main from './components/mercados/Main';
import SearchComponent from './components/mercados/landing_page/SearchComponent';

window.del_user = (id) => {
    JSAlert.confirm("¿Eliminar item?<br/><small>Todos los datos relacionados con el item se eliminarán...</small>")
        .then(result => {
            if(!result) return;
            livewire.emitTo('usuarios.table', 'del_item', id);
        });
}