import { ErrorBoundary } from "react-error-boundary";

const ErrorBox = ({ children }) => {
    const ErrorFallback = ({ error, resetErrorBoundary }) => {
        return (
            <div className='container main-content py-3' role="alert">
                <p className="h3">
                    <span className="fas fa-triangle-exclamation me-1 text-danger"></span>
                    Ocurrió un error:
                </p>
                <p>
                    <span className="pe-2">Mensaje:</span>
                    <span className="fw-bold">{error.message}...</span>
                </p>
                <div className="alert alert-danger" style={{ maxHeight: "400px", overflowY: 'auto' }}>
                    <pre>
                        {error.stack}
                    </pre>
                </div>
                <button className="btn btn-sm btn-link" onClick={resetErrorBoundary}>Volver...</button>
            </div>
        )
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} >
            {children}
        </ErrorBoundary>
    );
}

export default ErrorBox;