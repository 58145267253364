const FormError = ({ children }) => {
    return (
        <div className="text-danger">
            <small>
                <i className="fa-solid fa-circle-exclamation me-1"></i>
                {children}
            </small>
        </div>
    );
}

export default FormError;