import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
import { ConfigProvider, App, Empty } from "antd";
import ErrorBox from "../../common/ErrorBox";
import { customTheme } from "../../common/customTheme";
import { getCategorias, getItems } from "./functions";
import { sprintf } from "sprintf-js";

export default function SearchComponent({ mid }) {
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);

    const [params, setParams] = useState({
        texto: "",
        mercado_id: mid,
        categoria: "",
        subcategoria: ""
    });

    const [items, setItems] = useState([]);

    useEffect(() => {
        Promise.all([
            find_items(),
            fillCategorias()
        ]);
    }, []);

    useEffect(() => {
        let cat = categorias.find(c => c.id === parseInt(params.categoria));
        console.log(params.categoria, cat);

        setParams(prev => ({ ...prev, subcategoria: "" }));
        if (cat) {
            setSubcategorias(cat.subcategorias);
        } else { setSubcategorias([]); }
    }, [params.categoria])

    const fillCategorias = async () => {
        let res = await getCategorias(mid);
        setCategorias(res.data);
    }

    const find_items = async () => {
        let res = await getItems(params);
        setItems(res.data);
    }

    const search_keyup = async (e) => {
        if(e.key === 'Enter') {
            find_items();
        }
    }

    return (
        <div>
            <div className="text-white py-3 text-center mercados-color-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md"></div>
                        <div className="col-md-10">
                            <div className="row">
                                <div className="col-md pb-1">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input type="text" className="form-control" placeholder="Buscar"
                                            value={params.texto}
                                            onChange={(e) => { setParams(prev => ({ ...prev, texto: e.target.value })) }}
                                            onKeyUp={(e) => search_keyup(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md pb-1 d-flex">
                                    <select name="" id="" className="form-select form-select-sm"
                                        placeholder="Categoría"
                                        value={params.categoria}
                                        onChange={(e) => setParams(prev => ({ ...prev, categoria: e.target.value }))}
                                    >
                                        <option value="">Categoría(Todos)</option>
                                        {
                                            categorias.map(cat => (
                                                <option key={cat.id} value={cat.id}>{cat.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    <select name="" id="" className="form-select form-select-sm ms-2"
                                        onChange={(e) => setParams(prev => ({ ...prev, subcategoria: e.target.value }))}
                                    >
                                        <option value="">Subcategoría(Todos)</option>
                                        {
                                            subcategorias.map(scat => (
                                                <option key={scat.id} value={scat.id}>{scat.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    <button className="btn btn-dark ms-2 px-4"
                                        onClick={() => find_items()}
                                    >
                                        Buscar
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="col-md"></div>
                    </div>
                </div>
            </div>

            <div className="py-3">
                <div className="container">
                    <div className="row">
                        {
                            (items.length > 0) ?
                                items.map(it => (
                                    <div key={it.store_id} className="p-2 my-4 px-3 flex-shrink-2 col-md-4">
                                        <div className="text-center">
                                            <a href={ sprintf('https://cat.quick.com.bo/%s', it.slug) } target="_blank">
                                                <img src={`${it.logo}`}
                                                    className="m-1 rounded-3" alt=""
                                                    loading="lazy" style={{ height: '250px', objectFit: 'contain', maxWidth: '100%' }} />
                                            </a>
                                            <div>
                                                <b>{it.name_store}</b>
                                            </div>
                                        </div>
                                    </div>
                                )) : <Empty />
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

let main = document.getElementById('mercados-search');

if (main) {
    let mid = main.dataset.mid;
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <SearchComponent mid={mid} />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}