import { connector } from "../common/connector";

export const defaultItem = {
    id: 0,
    nombre: "",
    descripcion: "",
    categorias: [],
    paginas: [],
}

export const defaultCategoria = {
    id: 0,
    mercado_id: 0,
    nombre: ""
};

export const defaultSubcategoria = {
    id: 0,
    mercado_id: 0,
    categoria_id: 0,
    nombre: ""
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'FILL':
            return action.payload;

        case 'ADD':
            return [...state, action.payload];
        
        case 'UPDATE':
            return state.map(it => {
                if(it.id === action.payload.id) { return action.payload; }
                else { return it; }
            });
        
        case 'REMOVE':
            return state.filter(it => it.id != action.payload);
        
        default: return state;
    }
}

export const getItems = (params) => {
    return connector.get('api/mercados', { params: params});
}

export const createItem = (data) => {
    return connector.post('api/mercados', data);
}

export const updateItem = (data) => {
    return connector.put(`api/mercados/${data.id}`, data)
}

export const deleteItem = (id) => {
    return connector.delete(`api/mercados/${id}`);
}

/* imagen */
export const loadImage = (id, data) => {
    return connector.post(`api/mercados/${id}/load-image`, data);
}

export const delImage = (id) => {
    return connector.delete(`api/mercado/del_imagen/${id}`);
}

/* fin imagen */

/* categorías */
export const createCategoria = (data) => {
    return connector.post('api/mercados/categorias', data);
}

export const updateCategoria = (data) => {
    return connector.put(`api/mercados/categorias/${data.id}`, data);
}

export const deleteCategoria = (id) => {
    return connector.delete(`api/mercados/categorias/${id}`);
}
/* fin categorías */

/* subcategorías */
export const createSubcategoria = (data) => {
    return connector.post('api/mercados/subcategorias', data);
}

export const updateSubcategoria = (data) => {
    return connector.put(`api/mercados/subcategorias/${data.id}`, data);
}

export const deleteSubcategoria = (id) => {
    return connector.delete(`api/mercados/subcategorias/${id}`);
}
/* fin subcategorías */

/* paginas */

export const createPagina = (data) => {
    return connector.post('api/mercados/paginas', data);
}

export const updatePagina = (data) => {
    return connector.put(`api/mercados/paginas/${data.id}`, data);
}

export const deletePagina = (id) => {
    return connector.delete(`api/mercados/paginas/${id}`);
}

/* fin paginas */