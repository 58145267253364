import { createContext } from "react";

export const EditableContext = createContext({});

export const EditableContextProvider = ({children, data}) => {
    return (
        <EditableContext.Provider value={data}>
            {children}
        </EditableContext.Provider>
    );
}
