import { useContext, useEffect, useState } from "react";
import { EditableContext } from "../common/EditableContextProvider";
import { Select, Table, Modal } from "antd";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
const { Column } = Table;
import FormError from "../common/FormError";

const SubcategoriasComponent = () => {
    const { editItem, categorias, subcategorias } = useContext(EditableContext);
    const [selcat, setSelcat] = useState("");
    const [subcatItems, setSubcatItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editSubcat, setEditSubcat] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let cats = editItem.categorias;
        cats = cats.map(cat => {
            if(cat.id !== selcat) { return cat; }
            else { return {...cat, subcategorias: subcatItems}; }
        });
        categorias.updateEditItemCategorias(cats);
    }, [subcatItems]);

    const handle_change_cat = (value) => {
        setSelcat(value);
        let cat = editItem.categorias.find(cat => cat.id === value);
        setSubcatItems(cat.subcategorias);
    }
    
    const handle_new = () => {
        setEditSubcat({
            id: 0,
            mercado_id: editItem.id,
            categoria_id: 0,
            nombre: ""
        });
        setShowModal(true);
    }

    const handle_edit = (item) => {
        setEditSubcat(item);
        setShowModal(true);
    }

    const handle_submit = async (values) => {
        let data = {...values, categoria_id: selcat};

        setLoading(true);
        try {
            let action = (values.id)? 
                subcategorias.updateItemSubcategoria(data) : 
                subcategorias.addItemSubcategoria(data);  
            let it= await action;

            if(values.id) {
                setSubcatItems(prev => prev.map(cat => {
                    if(cat.id !== values.id) { return cat; }
                    else { return it; }
                }));
            } else { setSubcatItems(prev => ([...prev, it])); }

            setShowModal(false);
        } catch (error) { console.log(error.message); }
        finally { setLoading(false); }
    }

    const handle_remove = (id) => {
        Modal.confirm({
            title: "Confirmar",
            content: "¿Eliminar item?",
            onOk: async () => {
                try {
                    let action = subcategorias.removeItemSubcategoria(id);
                    await action;
                    setCatItems(prev => prev.filter(it => it.id!== id));
                    setShowModalCat(false);
                } catch (error) { console.log(error.message); }
            }
        });
    }

    const catValidationSchema = Yup.object({
        nombre: Yup.string()
            .min(3, 'Texto muy corto')
            .max(200, 'Texto muy largo')
            .required('El campo es obligatorio')
    });

    const ActionButtons = ({ item }) => {
        return (
            <>
                <button className="btn btn-sm btn-action btn-light border py-0" title="Editar"
                    onClick={() => handle_edit(item)} >
                    <span className="fas fa-pen text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
                <button className="btn btn-sm btn-action btn-light border py-0 ms-1" title="Eliminar"
                    onClick={() => handle_remove(item.id)} >
                    <span className="fas fa-trash text-sm" style={{ fontSize: '0.85em' }}></span>
                </button>
            </>
        );
    }

    return (
        <>
            <div className="mb-2">
                <div className="row">
                    <div className="col-auto">
                        <label>Categoría:</label>
                    </div>
                    <div className="col-4">
                        <Select
                            className="w-100"
                            options={editItem.categorias.map(cat => ({
                                label: cat.nombre, value: cat.id
                            }))}
                            value={selcat}
                            onChange={handle_change_cat}
                        ></Select>
                    </div>
                </div>
            </div>
            {
                (selcat)? (
                    <div className="mb-2">
                        <div className="mb-2">
                            <button className="btn btn-sm btn-link"
                                onClick={() => handle_new()}
                            >
                                [+] Agregar
                            </button>
                        </div>
                        <Table
                            size="small"
                            dataSource={subcatItems}
                            rowKey="id"
                            scroll={{ y: 400, }}
                        >
                            <Column key="·" title="" dataIndex="id"
                                width={80}
                                render={(value, row) => (<ActionButtons item={row} />)}
                            />
                            <Column key="id" title="ID" dataIndex="id" width={60} />
                            <Column key="nombre" title="Nombre" dataIndex="nombre" size={200} />
                        </Table>
                        <Modal
                            open={showModal}
                            onCancel={() => setShowModal(false)}
                            footer={null}
                            destroyOnClose={true}
                        >
                            <Formik
                                initialValues={editSubcat}
                                onSubmit={handle_submit}
                                validationSchema={catValidationSchema}
                            >
                                {
                                    (formik) => (
                                        <Form>
                                            <fieldset disabled={loading}>
                                                <div className="mb-2">
                                                    <label>Nombre</label>
                                                    <input type="text" className="form-control form-control-sm"
                                                        value={formik.values.nombre}
                                                        onChange={(e) => formik.setFieldValue('nombre', e.target.value)}
                                                    />
                                                    <ErrorMessage name="nombre" component={FormError} />
                                                </div>
                                                <div className="mb-2">
                                                    <button className="btn btn-sm btn-dark">
                                                        Guardar
                                                    </button>
                                                </div>
                                            </fieldset>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Modal>
                    </div>
                ) : (<></>)
            }
        </>
    );

}

export default SubcategoriasComponent;