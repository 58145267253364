import React, { useEffect, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import TableComponent from "./TableComponent";
import { getItems } from "./functions";
import dayjs from "dayjs";
import { Modal } from "antd";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

export default function Main() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: { "current": 1, "pageSize": 50, "total": 0 },
        filters: {},
        sorter: {},
    });
    const searchInput = useRef(null);
    const { confirm } = Modal;

    useEffect(() => {
        getList(tableParams);
    }, []);

    const test = document.getElementById("test");
    test.onclick = () => {
        getList(tableParams);
    };

    const Expiration = ({ value }) => {
        if (!value) { return (<span>-</span>); }

        const end_suscr = dayjs(value);
        if (end_suscr.isSame(dayjs('1001-01-01'))) {
            return (<span className="text-primary fw-bold">
                <i className="fa-solid fa-caret-right me-1"></i>
                INDEFINIDO
            </span>);
        }

        if (dayjs().isSameOrBefore(end_suscr)) {
            return (
                <span className="text-success fw-bold" >
                    <i className="fa-solid fa-caret-up me-1"></i>
                    {end_suscr.format("DD/MM/YYYY")}
                </span>
            );
        } else {
            return (
                <span className="text-danger fw-bold">
                    <i className="fa-solid fa-caret-down me-1"></i>
                    {end_suscr.format("DD/MM/YYYY")}
                </span>
            );
        }
    }

    const getList = async (params) => {
        try {
            setLoading(true);
            let { data } = await getItems(params);
            let { rows, total, current } = data;
            setItems(rows);
            setTableParams(prev => {
                return {
                    ...prev,
                    pagination: {
                        ...prev.pagination,
                        total: total,
                        current: current
                    }
                }
            });
        } catch (error) { throw new Error(error); }
        finally { setLoading(false); }
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        setTableParams(prev => {
            return { ...prev, ...params };
        });
        getList(params);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, setSelectedKeys, confirm) => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className="p-1" onKeyDown={e => e.stopPropagation()}>
                <input type="text" className="form-control form-control-sm"
                    ref={searchInput}
                    placeholder='Escribe algo...'
                    value={(selectedKeys[0]) ? selectedKeys[0] : ""}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') { handleSearch(selectedKeys, confirm, dataIndex) }
                    }}
                />
                <div className="mt-1 d-flex justify-content-center">
                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    >
                        <span className="fas fa-search"></span>
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => clearFilters && handleReset(clearFilters, setSelectedKeys, confirm)}
                    >
                        Reset
                    </button>

                    <button className="btn btn-sm btn-light border mx-1"
                        onClick={() => { close(); }}
                    >
                        Salir
                    </button>
                </div>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) { setTimeout(() => searchInput.current?.select(), 100); }
        }
    });

    const confirmDelete = (id) => {
        confirm({
            title: 'Confirmación',
            content: '¿Eliminar?',
            onOk: () => {
                livewire.emitTo('usuarios.form', 'del_item', id)
            }
        });
    }

    const OptionsColumn = ({ item }) => {
        return (
            <div>
                <button className="btn btn-outline-secondary py-0 px-1 me-1"
                    onClick={() => { livewire.emitTo('usuarios.form', 'set_item', item.id) }}
                >
                    <span className="fas fa-pen"></span>
                </button>
                <button className="btn btn-outline-secondary py-0 px-1"
                    onClick={() => confirmDelete(item.id)}
                >
                    <span className="fas fa-trash"></span>
                </button>
            </div>

        );
    }

    const columns = [
        {
            title: "*", key: "*", width: 100,
            render: (value, row) => (<OptionsColumn item={row} />)
        },
        { title: "ID", dataIndex: "id", key: "id", width: 80, sorter: true },
        {
            title: "NOMBRE", dataIndex: "nombre", key: "nombre", width: 200, sorter: true,
            render: (value, row) => {
                return (row.tipo === 'CLIENTE') ? (<a href={`/usuarios/${row.id}/suscripciones`}>{value}</a>) : (<span>{value}</span>);
            },
            filterDropdown: getColumnSearchProps('nombre').filterDropdown,
            onFilterDropdownOpenChange: getColumnSearchProps('nombre').onFilterDropdownOpenChange
        },
        { title: "TIPO", dataIndex: "tipo", key: "tipo", width: 150, sorter: true },
        { title: "EMAIL", dataIndex: "email", key: "email", width: 240, sorter: true },
        { title: "CELULAR", dataIndex: "celular", key: "celular", width: 150, sorter: true },
        {
            title: "FECHA EXP", dataIndex: "fecha_exp", key: "fecha_exp", width: 120, sorter: true,
            render: (value, row) => (<Expiration value={row.max_suscription?.end_suscription} />)
        },
        {
            title: "REGISTRO", dataIndex: "obs", key: "obs", width: 120,
            render: (value, row) => (value)? (<>PRUEBA</>) : (<>INTERNO</>)
        },
        {
            title: "ACTIVO", dataIndex: "activo", key: "activo", width: 100, sorter: true,
            render: (value) => (value) ? (
                <span><span className="fas fa-circle-dot pe-1 text-success"></span>Activo</span>
            ) : (
                <span><span className="fas fa-circle-dot pe-1 text-danger"></span>Inactivo</span>
            )
        },
        {
            title: "RUBRO", dataIndex: "u_rubro", key: "u_rubro", width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps('u_rubro').filterDropdown,
            onFilterDropdownOpenChange: getColumnSearchProps('u_rubro').onFilterDropdownOpenChange
        },
        {
            title: "SUBRUBRO", dataIndex: "u_subrubro", key: "u_subrubro", width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps('u_subrubro').filterDropdown,
            onFilterDropdownOpenChange: getColumnSearchProps('u_subrubro').onFilterDropdownOpenChange
        },
        {
            title: "PAÍS", dataIndex: "u_pais", key: "u_pais", width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps('u_pais').filterDropdown,
            onFilterDropdownOpenChange: getColumnSearchProps('u_pais').onFilterDropdownOpenChange
        },
        {
            title: "CIUDAD", dataIndex: "u_ciudad", key: "u_ciudad", width: 150,
            sorter: true,
            filterDropdown: getColumnSearchProps('u_ciudad').filterDropdown,
            onFilterDropdownOpenChange: getColumnSearchProps('u_ciudad').onFilterDropdownOpenChange
        },

    ];

    return (
        <div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                columns={columns}
                scroll={{ y: 400, }}
            />
        </div>
    );
}

let main = document.getElementById('usuarios-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                <Main />
            </ErrorBoundary>
        </React.StrictMode>
    );
}

